// ko.validation.init({
//   errorElementClass: 'has-error',
//   errorMessageClass: 'help-block',
//   decorateElement: true,
//   registerExtenders: true,
//   messagesOnModified: true,
//   insertMessages: true,
//   parseInputAttributes: true,
//   messageTemplate: null
// });

ko.validation.locale('es-ES');

// Custom Bindings
ko.bindingHandlers.initializeValue = {
  init: function(element, valueAccessor) {
    valueAccessor()(element.getAttribute('value'));
  },
  update: function(element, valueAccessor) {
    element.setAttribute('value', ko.utils.unwrapObservable(valueAccessor()));
  }
};
