// Config blockUI
_.extend($.blockUI.defaults.css, {
  border: 'none',
  padding: '21px'
});

// Add csrf token to ajax requests
add_csrf_token = function(xhr) {
  xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
};


// Configure underscore template interpolation to use {{ variable }}
_.templateSettings = {
  evaluate: /\{\{#([\s\S]+?)\}\}/g, // {{# console.log("blah") }}
  interpolate: /\{\{[^#\{]([\s\S]+?)[^\}]\}\}/g, // {{ title }}
  escape: /\{\{\{([\s\S]+?)\}\}\}/g, // {{{ title }}}
};
